@import '../utils/styles/Base/mixin';



search-input{
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 17px;

    .inputBox{
        min-height: 53px;
        width: 100%;
        border: 0;
        border-radius: 34px;
        box-sizing: border-box;
        padding: 0 5px;
        font-size: 18px;
        color: #666;
        display: grid;
        grid-template-columns: 53px auto 60px 53px;
        flex-wrap: wrap;
        align-items: center;
        background: #fff;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;

        &.ltrPage{
            .isRTL span{
                justify-content: end;
            }
        }
        @include devices(minitablet) {
            grid-template-columns: 20px auto 70px 40px;
            .btnKB{
                visibility: hidden;
            }
        }

        button{
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: 0;
            padding: 0;
            cursor: pointer;
            font-size: 24px;
            font-family: 'afruz','ardeshir','sahel';
            p{
                margin: 0;
            }
        }

        
        .input{
            position: relative;
            &.isUnicode{
                input, span{
                    font-family: 'roboto','ardeshir','sahel' !important;
                }
            }
            &.isRTL {
                input, span{
                    &:not(:placeholder-shown){
                        direction: rtl;
                        text-align: inherit;
                    }
                }
            }
            input{
                position: relative;
                display: inline-block;
                vertical-align: baseline;
                border: 0;
                border-radius: 0;
                max-width: 100%;
                padding: 4px 0 4px 2px;
                width: 100%;
                font-size: 16px;
                color: #333;
                background-color: transparent;
                margin-bottom: 0;
                outline: 0;
                font-family: 'afruz','ardeshir','sahel';
                z-index: 100;
                &:not(:placeholder-shown){
                    direction: initial;
                }
                &::placeholder{
                    font-family: 'afruz','ardeshir','sahel' !important;
                }
            }
            span{
                font-family: 'afruz','ardeshir','sahel';
                position: absolute;
                top: 0;
                padding: 4px 0 4px 2px;
                color: transparent;
                font-size: 16px;
                width: 100%;
                display: none;
                white-space: pre;
                overflow: hidden;
               &.active{
                    display: flex;
                
               }
                s-sign{
                    display: none;
                    @include devices(minitablet) {
                        display: block;
                        width: 1px;
                        background-color: black;
                        // box-sizing: border-box;
                        margin: 0 1px;
                        animation-name: blackWhite;  
                        animation-iteration-count: infinite;  
                        animation-duration: 1s; 
                    }

                    @keyframes blackWhite {  
                        0% { background-color: transparent; }
                        50% { background-color: transparent; }
                        51% { background-color: black; }
                        100% { background-color: black; }
                    }
                }
            }

        }

    }


    .keyboard{
        position: absolute;
        left: 0;
        top: 62px;
        margin: -0 60px;
        overflow: hidden;
        box-sizing: border-box;
        width: -webkit-fill-available;
        transition: all 0.3s ease-in-out;
        height: 0;
        opacity: 0;
        box-shadow: 0 0 0 0;
        @include devices(minitablet) {
            opacity: 1;
            bottom: -20px !important;
            transition-duration: 0.2s;
        }

        &.show{
            height: 207px;
            visibility: visible;
            opacity: 1;
            box-shadow: 0 10px 16px 8px #00000066, 0 0 15px 0 #00000030;
            @include devices(minitablet) {
                bottom: 0 !important;
                height: 216px;
            }
        }
    }
}
@import '../utils/styles/Base/mixin';


change-tr{
    font-size: 16px;
    .trBtn{
        display: flex;
        gap: 3px;
        background-color: #ccc;
        padding: 7px 5px;
        border-radius: 6px;
        height: 36px;
        width: 57px;
        box-sizing: border-box;
        justify-content: center;

        &:hover{filter: brightness(93%)}
        &:active{filter: brightness(85%);}

        &.active{filter: brightness(85%);}
    }

    .modal{
        position: absolute;
        display: flex;
        justify-content: end;
        right: 0;
        left: 0;
        z-index: 100;
        @include devices(minitablet) {
            position: fixed;
            bottom: 0;
        }
        
        .box{
            display: grid;
            grid-template-rows: 40px auto;
            height: 0;
            overflow: hidden;
            padding: 0 20px;
            box-sizing: border-box;
            box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
            background-color: white;
            margin: 20px;
            transition: all 0.3s ease-in-out;
            border-radius: 6px;

            &.showLangs{
                height: 258px;
                padding: 20px;
            }
            @include devices(minitablet) {
                width: 100%;
                margin: 0;
                transition: all 0.2s ease-in-out;
                border-radius: 0;
                &.showLangs{
                    height: 258px;
                    padding: 20px 10px;
                }
            }
            
            .mHead{
                padding: 0  20px;
                font-size: 20px;
                text-align: end;
            }
            .langsList{
                width: 50%;
                display: grid;
                grid-template-columns: auto auto auto;
                width: 100%;
                justify-content: center;
                
                .list{
                    display: grid;
                    background-color: #eeeeee;
                    border-radius: 6px;
                    overflow: hidden;
                    
                    span{
                        padding: 10px 30px;
                        
                        &.active{
                            background-color: #d6d6d6;
                        }
                        &:hover{
                            background-color: #b6b6b6;
                        }
                    }
                }
    
                .arrow{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 30px;
                    color: #adadad;
                    padding: 0 10px;
                }
            }
        }

    }
}
@import '../../../utils/styles/Base/mixin';

lang-modal{
    position: fixed;
    visibility: hidden;
    opacity: 0;    
    direction: ltr;
    display: flex;
    justify-content: end;
    z-index: 1000;
    left: 0;
    right: 0;
    height: 0;
    font-family: 'afruz','ardeshir','sahel';

    *{
        text-align: center !important;
    }
    &.show{
        visibility: visible;
        opacity: 1;
    }

    @include devices(minitablet) {
        background-color: #00000066;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        margin: 0;
        top: 0;

        .back{
            position: absolute;
            width: 100%;
            height: 100vh;
        }
        
        .content{
            max-width: 500px;
            margin: 15% auto;
            box-shadow:none;
        }
    }

    .header{
        
        .close{
            display: flex;
            background-size: cover;
            background-position: center center;
            padding: 15px;
            position: relative;
            overflow: hidden;
        }
    }

    .content{
        background-color: #fefefe;
        margin: 0 40px;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        max-width: 200px;
        display: block;
        z-index: 10;
        border-radius: 8px;
        position: absolute;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;

        .langsList{
            display: grid;
            // justify-content: center;
            
            span{
                padding: 10px 30px;
                font-size: 20px;
                cursor: pointer;
                color: black;

                &.active{
                    background-color: #e9e9e9;
                }
                &:hover{
                    background-color: #ccc;
                }
            }
        }
    }
}
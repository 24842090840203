
main-page{

    .section{
        text-align:center;
        overflow: hidden;
        z-index: 2;
        text-align: left;
    }

    .rtl{
        *{
            direction: rtl;
            text-align: right;
        }

    }

    .unicode{
        font-family: 'ardeshir','sahel', roboto;
    }
}



word-result{



    .bestWord{
        // text-align: right;
        padding: 1.25em 1.5em 1.5em 1.5em;
        line-height: 1.6;
        font-size: 0.875em;
        // -webkit-transition: all 0.5s ease-in-out;
        // -moz-transition: all 0.5s ease-in-out;
        // -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        opacity: 0;
        border-radius: 5px;
        border: 1px solid #868686;
        margin-top: 2em;

        &.showWord{
            opacity: 1;
        }

        h1{
            margin: 0;
        }

        .wrdFeatures{
            margin: 1em 0;
        }
    }

    .sameRoots{

        .title{
            h3{
                color: #939393;
                font-size: 15px;
                line-height: 22px;
                font-weight: 500;
                display: flex;
                gap: 15px;
                align-items: center;
                &::after{
                    width: 87%;
                    height: 1px;
                    // opacity: 0.2;
                    background-color: #939393;
                    height: 1px;
                    content: "";
                    flex: 1;
                    margin-top: 5px;
                }
            }
        }

        .list{
            margin: 1em 0;
            display: flex;
            gap: 15px;
            .sameRootWord{
                cursor: pointer;
                &:hover{
                    color: #0f087b;
                }
            }
        }
    }


    .similarWordBox{
        padding: 1.25em 1.5em 1.5em 1.5em;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        &.showWord{
            opacity: 1;
        }

        header{
            
            h3{
                color: #939393;
                font-size: 15px;
                line-height: 22px;
                font-weight: 500;
                display: flex;
                gap: 15px;
                align-items: center;
                &::after{
                    width: 87%;
                    height: 1px;
                    // opacity: 0.2;
                    background-color: #939393;
                    height: 1px;
                    content: "";
                    flex: 1;
                    margin-top: 5px;
                }
            }
        }
    }

}
@font-face {
    font-family: 'afruz';
    src: url('./assets/fonts/afruz.ttf');
}
@font-face {
  font-family: 'ardeshir';
  src: url("./assets/fonts/Ardeshir.ttf") format('truetype');
	// unicode-range: U+10B00, U+10B3F;
}
@font-face {
  font-family: 'sahel';
  src: url("./assets/fonts/Sahel-WOL.ttf") format('truetype');
	// unicode-range: U+10B00, U+10B3F;
}

  
body{
  margin: 0;
}


.App {
  font-family: 'afruz','ardeshir','sahel';
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.erio *{
  font-family: 'afruz';
}
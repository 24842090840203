

main-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    box-sizing: border-box;
    padding: 0.5em 3em;
    // padding: 5px 35px;
    font-size: 0.72em;
    color: #777;
    letter-spacing: 0.017em;
    top: 0;

    .logo{
        display: inline-flex;
        padding: 0.8em 0;
        font-size: 1.72em;
        color: #777;
        letter-spacing: 0.017em;
        font-family: 'afruz','ardeshir','sahel';
    }
    

    button{
        padding: 0.8em 0;
        font-size: 1.72em;
        color: #777;
        letter-spacing: 0.017em;
        background: transparent;
        border: 0;
        cursor: pointer;
        &:hover{
            color: #4a4a4a;
        }
    }
}
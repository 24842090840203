@import '../../utils/styles/Base/mixin';

search-section{

    min-height: 100vh;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    overflow: hidden;

    .form{
        width: 100%;
        max-width: 870px;
        margin-top: 30vh;
        transition: all 0.5s ease-in-out;
        @include devices(mobile) {
            margin-top: 25vh !important;
        }

        &.searchMode{
            margin-top: 0 !important;

            @include devices(minimonitor) {
                margin-top: 55px !important;
            }

            .headeTitle{
                height: 0 !important;
                margin: 0 !important;
                opacity: 0 !important;
            }
        }

        .headeTitle{
            width: 100%;
            height: 50px;
            opacity: 1;
            font-size: 36px;
            font-weight: 800;
            color: #000;
            text-align: center;
            margin-bottom: 50px;
            transition: all 0.5s ease-in-out;
        }
    }
}


.similarWord{
    h2{
        cursor: pointer;
        margin: 0;
        padding-top: 0.5em;
        font-size: 22px;
        &:hover{
            color: #0f087b;
        }
    }
}



.loader{
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    z-index: 0;
    text-align: center !important;
    vertical-align: middle;
    background-color: transparent;
    margin-top: 40vh;
    // -webkit-transition: all 0.5s ease-in-out;
    // -moz-transition: all 0.5s ease-in-out;
    // -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 50px;

    &.showLoader{
      opacity: 1;
      visibility: hidden;
    }
    
    .box {
      width: 48px;
      height: 48px;
      display: inline-block;
      position: relative;
    
      &::after,
      &::before {
        content: '';  
        box-sizing: border-box;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #9e9e9e;
        position: absolute;
        left: 0;
        top: 0;
        animation: animloader 2s linear infinite;
      }
      &::after {
        animation-delay: 1s;
      }
    }
}



@keyframes animloader {
  0% {
    transform: scale(0);
    visibility: visible;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    visibility: hidden;
    opacity: 0;
  }
}
    
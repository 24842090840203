@import '../utils/styles/Base/mixin';

keyboard-layout{

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'afruz','ardeshir','sahel';
        direction: ltr !important;
    }

    .keyboardBase {
        direction: ltr;
        max-width: 1085px;
        padding: 6px;
        top: 50%;
        left: 50%;
        background-color: #ececec;
        border-radius: 5px;
        display: grid;
        grid-gap: 3px;
        z-index: 2;
        box-shadow: 0 10px 16px 8px #00000066, 0 0 15px 0 #00000030;

        &.isUnicode .key{
            font-family: 'roboto','ardeshir','sahel';
        }

        @include devices(minitablet) {
            margin: 0 !important;
            width: 100%;
            position: fixed !important;
            top: auto !important;
            bottom: 0px !important;
            border-radius: 0;
            grid-gap: 7px;
        }

        .row{
            display: flex;
            gap: 6px;
            // grid-template-columns: repeat(30, auto);
        }
    
        .key {
            box-shadow: 0 0 3px -1px #0000004d;
            height: 45px;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 5px;
            background: #fff;
            border-bottom: 1px solid #b5b5b5;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            max-width: 80px;
            font-size: 18px;
            overflow: hidden;
            min-width: 30px;
            @include devices(minimobile) {
                min-width: auto;
            }

            &:hover {
                // border: 1px solid #8d8d8d;
            }
            &:active{filter: brightness(90%);}
        }

        .alt{
            font-size: 18px;
        }

        .tab {
            grid-column: span 4;
            font-size: 18px;
        }
        
        .backspace {
            max-width: 120px;
        }
        
        .capslock {
            grid-column: span 4;
            font-size: 18px;
        }
        
        .return {
            grid-column: span 4;
            font-size: 18px;
            font-family: 'afruz','ardeshir','sahel' !important;
        }
        
        .shift {
            grid-column: span 5;
            font-size: 18px;
        }
        
        .Ctrl {
            grid-column: span 2;
            font-size: 18px;
        }
        
        .cmd {
            grid-column: span 2;
            font-size: 18px;
        }
        
        .space {
            max-width:none;
            @include devices(minitablet) {
                width: 65%;
            }
        }

        &.shiftActive{
            .shift{
                background-color: #b7b7fc;
            }
        }
    }
}